.single-blog-header {
  background: #0060b8;
  height: 20rem;
  padding-top: 6rem;
}
.single-blog-wrapper {
  background: #f1f1f1;
  border-radius: 8px;
}


.single-blog-heading {
  border-left: 2px solid;
  padding-left: 1rem;
}

.single-blog-container {
  margin-bottom: 14rem;
}
.more-share-link {
  /* height: 40px; */
  /* width: 40px; */
  /* border-radius: 50%; */
  /* background: #fe5f4f; */
  display: inline-block;
  /* padding-top: rem; */
  margin-left: 0.8rem;
  cursor: pointer;
}

.more-share-link p {
  font-size: 40px;
}

/* Blog List */

.blog-header {
  background: #fe5f4f;
  height: 20rem;
  padding-top: 6rem;
}
.blogList-container {
  margin-top: 6rem;
}

.blogCard {
  height: 30rem;
  background-color: #fafafa;
}
.blogCard img {
  /* min-height: 12rem; */
  height: 18rem;
  background-position: center center;
  background-repeat: no-repeat;
  /* width: 100%; */
  object-fit: cover;
}
.card-title {
  color: #000000;
}

.card-effect {
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);

  cursor: pointer;
}

.card-effect:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background-color: #ffffff;
}
.blogList-container {
  margin-bottom: 14rem;
}

.BlogList-title {
  height: 3.2rem;
}
.BlogList-bodyText {
  font-size: 0.8rem;
  color: #474747;
  font-weight: 300;
}
.main-blog-body {
  margin-top: 2.4rem;
  line-height: 1.6;
  margin-bottom: 2.4rem;
}

.blogCatLoct {
  font-size: 10px;
  color: #474747;
}



/* *****----- SideBar Blogs -----***** */

.sidebar-blog-wrapper {
  background-color: #f1f1f1;
  border-radius: 8px;
}
.sidebar-blog-heading h1 {
  font-size: 1.5rem;
  line-height: 1.5;
}

.sidebar-img img {
  border-radius: 8px;
}
.sidebolg-blogtitle {
  font-size: 16px;
  max-height: 5rem;
  color: #474747;
  font-weight: 500;
}
.sidebolg-blogtext {
  font-weight: 500;
  font-size: 10px;
  line-height: 1.5;
  font-style: normal;
  color: #474747;
}
a {
  text-decoration: none;
}

.singleExtraCol{
  border: 1px solid;

  
}

/* *****----- Featured Blogs -----*****  */
.featured-blog-title {
  font-size: 14px;
  color: #474747;
}

.featured-blog-body {
  font-size: 10px;
  line-height: 1.5;
  font-style: normal;
  color: #474747;
  font-weight: 500;
}
