.navbar {
  background: rgba(31, 30, 30, 0.5);
  backdrop-filter: blur(25px);
  /* -webkit-backdrop-filter: blur(10px) saturate(100%) contrast(45%)
      brightness(130%); */
  border-radius: 0 0 8px 8px;
}

.nav-container a {
  font-family: "Pacifico", cursive;
  color: #fff !important;

}


.nav-container a:hover {
  font-family: "Pacifico", cursive;
  color: #fe5f4f !important;

}

.footer-container {
  background: url(../../assets/Images/abc.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: 0;
  
}


.error-container {
  margin-top: 6rem;
}

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  padding: 10px 20px;
  background: #fe5f4f;

  margin: 20px 0;
  display: inline-block;
}

.link_404 a {
  color: #fff !important;
}
.contant_box_404 {
  margin-top: -50px;
}
