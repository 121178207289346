.header-container {
  padding-top: 8rem;
  position: relative;
}

.header {
  background: #0060b8;
  /* padding-top: 3rem; */
}

.header img {
  width: 30rem;
  height: auto;
}
.rohan {
  color: #fe5f4f;
}

/* *****----- Header Animation -----***** */
.cyanHash img {
  width: 40px;
  height: 45px;
  animation: rotation 6s infinite linear;
  margin-bottom: -20rem;
  text-shadow: 25px 25px 25px rgba(0, 0, 0, 0.25);
  margin-top: -24rem;
}

.redSquare img {
  animation: rotationCC 2s infinite linear;
  width: 50px;
  height: 50px;
  margin-bottom: -20rem;
}

.key-skills {
  background: #fe5f4f;
  box-shadow: 0px 50px 50px rgba(0, 0, 0, 0.25);
  /* position: relative; */
  /* height: 20rem; */
  margin-top: 14rem;
  margin-bottom: 14rem;
  /* padding-top: 2rem; */
}

.skill-icon {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 6.6rem;
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background: #ffffff;
}
.icon {
  -webkit-animation-duration: 1.4s;
  animation-duration: 1.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 4;
  -webkit-animation-iteration-count: 4;
}
.icon:hover {
  cursor: pointer;
  animation-name: bounce;
  -moz-animation-name: bounce;
}

.skill-icon img {
  width: 50px;
  height: 50px;
}

/* *****----- End -----***** */

/* *****----- Technology Familarity -----***** */

.tech-fam {
  margin-bottom: 14rem;
  /* margin-top: 14rem; */
}

/* *****----- Technology Familarity Animation-----***** */

.screw img {
  animation: screw 2s infinite linear;
  width: 50px;
  height: 50px;
  /* padding-top: -1rem; */
}

.limeTriangle img {
  animation: rotation 4s infinite linear;
  width: 50px;
  height: 50px;
}
.tech-fam-card {
  width: 16rem;
  height: 22rem;
  border-radius: 8px;
  cursor: pointer;
  -webkit-transition: background-color 0.4s ease-out;
  -moz-transition: background-color 0.4s ease-out;
  -o-transition: background-color 0.4s ease-out;
  transition: background-color 0.4s ease-out;
}

.tech-fam-card:hover {
  background: #0060b8;
  color: #ffffff;
}

.tech-fam-img img {
  width: 6rem;
  height: 6rem;
}

.tech-fam-title {
  height: 3.6rem;
}

/* *****----- End -----***** */

/* *****----- Quote -----***** */

.quote {
  background: #fe5f4f;
  box-shadow: 0px 50px 50px rgba(0, 0, 0, 0.25);
  min-height: 24rem;
  position: relative;
  margin-top: 14rem;
}
.invCom {
  font-size: 4rem;
}

.quote-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.quote-div h1 {
  font-family: "Dancing Script", cursive;
}

.quote-img img {
  width: 20rem;
}

/* *****----- End -----***** */

/* *****----- Blogs -----***** */

.blog-container {
  margin-top: 14rem;
  background: #0060b8;
}
.blog-heading {
  /* padding-top: 16rem; */
  color: #ffffff;
  text-shadow: 25px 25px 25px rgba(0, 0, 0, 0.25);
}

.blog-card-img {
  background-color: rgba(57, 62, 93, 0.7);
  /* padding: 0; */
  overflow: hidden;
  max-width: 30rem;
  /* margin: 5px; */
  border-radius: 8px;
}

.blog-card-img article {
  padding: 10%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.blog-card-img h2 {
  color: #fff;
  font-weight: 600;
  border-bottom: #fff solid 1px;
  font-size: 1.8rem;
  padding-bottom: 1rem;
}

.blog-card-img h4 {
  font-weight: 400;
  color: #fff;
  font-size: 1rem;
}

.blog-card-img img {
  width: 100%;
  height: 16rem;
  opacity: 0.8;
  top: 0;
  left: 0;
  -webkit-transition: all 4s ease;
  -moz-transition: all 4s ease;
  -o-transition: all 4s ease;
  -ms-transition: all 4s ease;
  transition: all 4s ease;
  object-fit: cover;
}

/*hovers*/

.blog-card-img:hover {
  cursor: pointer;
}

.blog-card-img:hover img {
  opacity: 0.1;
  transform: scale(1.5);
}

.blog-card-img:hover article {
  transform: translate(2px, -69px);
  -webkit-transform: translate(2px, -69px);
  -moz-transform: translate(2px, -69px);
  -o-transform: translate(2px, -69px);
  -ms-transform: translate(2px, -69px);
}

.blog-card-img:hover .ver_mas {
  transform: translate(0px, 0px);
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  opacity: 1;
}

/* *****----- End -----***** */

/* *****----- Contact -----***** */

.contact-container {
  background: #0060b8;
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.contact-form {
  border-left: 1.2px solid #fff;
  /* border-right:2px  solid #fff; */
  width: 2rem;
}

input:invalid {
  border: 1.5px solid red;
}

/* *****----- End -----***** */

/* *****----- About -----***** */

.about-container {
  /* background: url(../../assets/Images/proj2.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover; */
  /* position: relative;
  height: 40rem; */
  margin-top: 14rem;
  margin-bottom: 14rem;
}



/* *****----- End -----***** */

/* *****----- Photography -----***** */



.photo-quote {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.invCom {
  font-size: 4rem;
}

/* *****-----  -----***** */
.watch-img {
  position: relative;
}

.watch-img-text {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
}

.photography-img {
  background: url(../../assets/Images/proj2.jpg);
  min-height: 30rem;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 12px;
  /* margin-top: 4rem; */
}

.photography-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(15px);
  border-radius: 12px;
}

/* *****----- End -----***** */

/* *****----- Projects -----***** */

.projects-container {
  margin-top: 14rem;
}

.project img {
  margin-top: -14rem;
  width: 50px;
  height: 50px;
}

.projects-cards {
  border-radius: 8px;
  position: sticky;
  max-width: 20rem;
  height: 20rem;
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.projects-cards:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.projects-cards .projects-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
}
.projects-cards .projects-image img {
  width: 100%;
  /* height: 10rem; */
  transition: 0.5s;
  height: 20rem;
}
.projects-cards:hover .projects-image img {
  opacity: 0.5;
  transform: translateX(30%); /*100%*/
}

.project-description {
  color: #000000 !important;
}

.projects-cards .projects-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%; /*100%*/
  height: 100%;
  background: #fe5f4f;
  transition: 0.5s;
  transform-origin: left;
  transform: perspective(2000px) rotateY(-90deg);
}

.projects-cards:hover .projects-details {
  transform: perspective(2000px) rotateY(0deg);
}
.projects-cards .projects-details .center {
  padding: 20px;
  /* text-align: center; */
  background: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.projects-cards .projects-details .center h1 {
  color: #0060b8;
  line-height: 20px;
  font-size: 20px;
}

/* *****----- End -----***** */
