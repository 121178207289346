@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
html {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  /* font-family: Poppins; */
  font-family: 'Poppins', sans-serif;

}
.bor {
  border: 1px solid black;
}

.swing:hover {
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 4;
  animation-iteration-count: 4;
}

.loader{
  position: absolute;
  top:50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

@-webkit-keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}


@-webkit-keyframes swing {
  15% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }
  65% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  80% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes swing {
  15% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }
  65% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  80% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@-webkit-keyframes rotationCC {
  from {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes rotationCC {
  from {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@-webkit-keyframes screw {
  0%,
  100% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  50% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
}

@keyframes screw {
  0%,
  100% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  50% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
}

.navbar {
  background: rgba(31, 30, 30, 0.5);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  /* -webkit-backdrop-filter: blur(10px) saturate(100%) contrast(45%)
      brightness(130%); */
  border-radius: 0 0 8px 8px;
}

.nav-container a {
  font-family: "Pacifico", cursive;
  color: #fff !important;

}


.nav-container a:hover {
  font-family: "Pacifico", cursive;
  color: #fe5f4f !important;

}

.footer-container {
  background: url(/static/media/abc.3dc1782e.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: 0;
  
}


.error-container {
  margin-top: 6rem;
}

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  padding: 10px 20px;
  background: #fe5f4f;

  margin: 20px 0;
  display: inline-block;
}

.link_404 a {
  color: #fff !important;
}
.contant_box_404 {
  margin-top: -50px;
}

.header-container {
  padding-top: 8rem;
  position: relative;
}

.header {
  background: #0060b8;
  /* padding-top: 3rem; */
}

.header img {
  width: 30rem;
  height: auto;
}
.rohan {
  color: #fe5f4f;
}

/* *****----- Header Animation -----***** */
.cyanHash img {
  width: 40px;
  height: 45px;
  -webkit-animation: rotation 6s infinite linear;
          animation: rotation 6s infinite linear;
  margin-bottom: -20rem;
  text-shadow: 25px 25px 25px rgba(0, 0, 0, 0.25);
  margin-top: -24rem;
}

.redSquare img {
  -webkit-animation: rotationCC 2s infinite linear;
          animation: rotationCC 2s infinite linear;
  width: 50px;
  height: 50px;
  margin-bottom: -20rem;
}

.key-skills {
  background: #fe5f4f;
  box-shadow: 0px 50px 50px rgba(0, 0, 0, 0.25);
  /* position: relative; */
  /* height: 20rem; */
  margin-top: 14rem;
  margin-bottom: 14rem;
  /* padding-top: 2rem; */
}

.skill-icon {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 6.6rem;
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background: #ffffff;
}
.icon {
  -webkit-animation-duration: 1.4s;
  animation-duration: 1.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 4;
  -webkit-animation-iteration-count: 4;
}
.icon:hover {
  cursor: pointer;
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -moz-animation-name: bounce;
}

.skill-icon img {
  width: 50px;
  height: 50px;
}

/* *****----- End -----***** */

/* *****----- Technology Familarity -----***** */

.tech-fam {
  margin-bottom: 14rem;
  /* margin-top: 14rem; */
}

/* *****----- Technology Familarity Animation-----***** */

.screw img {
  -webkit-animation: screw 2s infinite linear;
          animation: screw 2s infinite linear;
  width: 50px;
  height: 50px;
  /* padding-top: -1rem; */
}

.limeTriangle img {
  -webkit-animation: rotation 4s infinite linear;
          animation: rotation 4s infinite linear;
  width: 50px;
  height: 50px;
}
.tech-fam-card {
  width: 16rem;
  height: 22rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.4s ease-out;
}

.tech-fam-card:hover {
  background: #0060b8;
  color: #ffffff;
}

.tech-fam-img img {
  width: 6rem;
  height: 6rem;
}

.tech-fam-title {
  height: 3.6rem;
}

/* *****----- End -----***** */

/* *****----- Quote -----***** */

.quote {
  background: #fe5f4f;
  box-shadow: 0px 50px 50px rgba(0, 0, 0, 0.25);
  min-height: 24rem;
  position: relative;
  margin-top: 14rem;
}
.invCom {
  font-size: 4rem;
}

.quote-div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.quote-div h1 {
  font-family: "Dancing Script", cursive;
}

.quote-img img {
  width: 20rem;
}

/* *****----- End -----***** */

/* *****----- Blogs -----***** */

.blog-container {
  margin-top: 14rem;
  background: #0060b8;
}
.blog-heading {
  /* padding-top: 16rem; */
  color: #ffffff;
  text-shadow: 25px 25px 25px rgba(0, 0, 0, 0.25);
}

.blog-card-img {
  background-color: rgba(57, 62, 93, 0.7);
  /* padding: 0; */
  overflow: hidden;
  max-width: 30rem;
  /* margin: 5px; */
  border-radius: 8px;
}

.blog-card-img article {
  padding: 10%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  transition: all 0.5s ease;
}

.blog-card-img h2 {
  color: #fff;
  font-weight: 600;
  border-bottom: #fff solid 1px;
  font-size: 1.8rem;
  padding-bottom: 1rem;
}

.blog-card-img h4 {
  font-weight: 400;
  color: #fff;
  font-size: 1rem;
}

.blog-card-img img {
  width: 100%;
  height: 16rem;
  opacity: 0.8;
  top: 0;
  left: 0;
  transition: all 4s ease;
  object-fit: cover;
}

/*hovers*/

.blog-card-img:hover {
  cursor: pointer;
}

.blog-card-img:hover img {
  opacity: 0.1;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.blog-card-img:hover article {
  transform: translate(2px, -69px);
  -webkit-transform: translate(2px, -69px);
  -moz-transform: translate(2px, -69px);
  -o-transform: translate(2px, -69px);
  -ms-transform: translate(2px, -69px);
}

.blog-card-img:hover .ver_mas {
  transform: translate(0px, 0px);
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  opacity: 1;
}

/* *****----- End -----***** */

/* *****----- Contact -----***** */

.contact-container {
  background: #0060b8;
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.contact-form {
  border-left: 1.2px solid #fff;
  /* border-right:2px  solid #fff; */
  width: 2rem;
}

input:invalid {
  border: 1.5px solid red;
}

/* *****----- End -----***** */

/* *****----- About -----***** */

.about-container {
  /* background: url(../../assets/Images/proj2.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover; */
  /* position: relative;
  height: 40rem; */
  margin-top: 14rem;
  margin-bottom: 14rem;
}



/* *****----- End -----***** */

/* *****----- Photography -----***** */



.photo-quote {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.invCom {
  font-size: 4rem;
}

/* *****-----  -----***** */
.watch-img {
  position: relative;
}

.watch-img-text {
  position: absolute;
  top: 75%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.photography-img {
  background: url(/static/media/proj2.b121d9d2.jpg);
  min-height: 30rem;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 12px;
  /* margin-top: 4rem; */
}

.photography-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.25);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  border-radius: 12px;
}

/* *****----- End -----***** */

/* *****----- Projects -----***** */

.projects-container {
  margin-top: 14rem;
}

.project img {
  margin-top: -14rem;
  width: 50px;
  height: 50px;
}

.projects-cards {
  border-radius: 8px;
  position: -webkit-sticky;
  position: sticky;
  max-width: 20rem;
  height: 20rem;
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.projects-cards:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.projects-cards .projects-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
}
.projects-cards .projects-image img {
  width: 100%;
  /* height: 10rem; */
  transition: 0.5s;
  height: 20rem;
}
.projects-cards:hover .projects-image img {
  opacity: 0.5;
  -webkit-transform: translateX(30%);
          transform: translateX(30%); /*100%*/
}

.project-description {
  color: #000000 !important;
}

.projects-cards .projects-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%; /*100%*/
  height: 100%;
  background: #fe5f4f;
  transition: 0.5s;
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform: perspective(2000px) rotateY(-90deg);
          transform: perspective(2000px) rotateY(-90deg);
}

.projects-cards:hover .projects-details {
  -webkit-transform: perspective(2000px) rotateY(0deg);
          transform: perspective(2000px) rotateY(0deg);
}
.projects-cards .projects-details .center {
  padding: 20px;
  /* text-align: center; */
  background: #fff;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.projects-cards .projects-details .center h1 {
  color: #0060b8;
  line-height: 20px;
  font-size: 20px;
}

/* *****----- End -----***** */

.single-blog-header {
  background: #0060b8;
  height: 20rem;
  padding-top: 6rem;
}
.single-blog-wrapper {
  background: #f1f1f1;
  border-radius: 8px;
}


.single-blog-heading {
  border-left: 2px solid;
  padding-left: 1rem;
}

.single-blog-container {
  margin-bottom: 14rem;
}
.more-share-link {
  /* height: 40px; */
  /* width: 40px; */
  /* border-radius: 50%; */
  /* background: #fe5f4f; */
  display: inline-block;
  /* padding-top: rem; */
  margin-left: 0.8rem;
  cursor: pointer;
}

.more-share-link p {
  font-size: 40px;
}

/* Blog List */

.blog-header {
  background: #fe5f4f;
  height: 20rem;
  padding-top: 6rem;
}
.blogList-container {
  margin-top: 6rem;
}

.blogCard {
  height: 30rem;
  background-color: #fafafa;
}
.blogCard img {
  /* min-height: 12rem; */
  height: 18rem;
  background-position: center center;
  background-repeat: no-repeat;
  /* width: 100%; */
  object-fit: cover;
}
.card-title {
  color: #000000;
}

.card-effect {
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);

  cursor: pointer;
}

.card-effect:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background-color: #ffffff;
}
.blogList-container {
  margin-bottom: 14rem;
}

.BlogList-title {
  height: 3.2rem;
}
.BlogList-bodyText {
  font-size: 0.8rem;
  color: #474747;
  font-weight: 300;
}
.main-blog-body {
  margin-top: 2.4rem;
  line-height: 1.6;
  margin-bottom: 2.4rem;
}

.blogCatLoct {
  font-size: 10px;
  color: #474747;
}



/* *****----- SideBar Blogs -----***** */

.sidebar-blog-wrapper {
  background-color: #f1f1f1;
  border-radius: 8px;
}
.sidebar-blog-heading h1 {
  font-size: 1.5rem;
  line-height: 1.5;
}

.sidebar-img img {
  border-radius: 8px;
}
.sidebolg-blogtitle {
  font-size: 16px;
  max-height: 5rem;
  color: #474747;
  font-weight: 500;
}
.sidebolg-blogtext {
  font-weight: 500;
  font-size: 10px;
  line-height: 1.5;
  font-style: normal;
  color: #474747;
}
a {
  text-decoration: none;
}

.singleExtraCol{
  border: 1px solid;

  
}

/* *****----- Featured Blogs -----*****  */
.featured-blog-title {
  font-size: 14px;
  color: #474747;
}

.featured-blog-body {
  font-size: 10px;
  line-height: 1.5;
  font-style: normal;
  color: #474747;
  font-weight: 500;
}

